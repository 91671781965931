const typeOfRequestData = [
  {
    index: 1,
    id: 1,
    name: "WD",
    description: "Wyszukiwanie dostawcy",
    typeOfRequest: "Wyszukiwanie dostawcy",
    value: "Wyszukiwanie dostawcy",
    text: "Wyszukiwanie dostawcy",
  },
  {
    index: 2,
    id: 2,
    name: "SRK",
    description: "Sprawdzanie rozliczenia karty",
    typeOfRequest: "Sprawdzanie rozliczenia karty",
    value: "Sprawdzanie rozliczenia karty",
    text: "Sprawdzanie rozliczenia karty",
  },
];

export { typeOfRequestData };
